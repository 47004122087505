<template>
<div class="container">
  <div class="contents">
<!--     <BlogDetail></BlogDetail> -->
    <!-- <BlogList @selectedBlog="blog => selectedBlog = blog"></BlogList> -->
    <router-view></router-view>
  </div>
  <div class="tool">
    <div class="search">
      <input placeholder="Search blog by key words" onfocus="this.placeholder=''"
      onblur="this.placeholder='Search blog by key words'" v-model="keyWord">
      <img src="../assets/icon/search.png" @click="serachByKeyWord()">
    </div>
    <div class="timeline">
      <ul>
        <!-- <li class="year" *ngFor="let element of yearMap | keyvalue : keyOrderDesc"
        (click)="openYear(element.key)">{{element.key}}
          <ul class="blog-by-year" [hidden]="!isYearOpen(element.key)">
            <li *ngFor="let blog of element.value">
              <a  [routerLink]="[{ outlets: { container: blog.id } }]"
              (click)="$event.stopPropagation()">{{blog.date | date}}</a>
            </li>
          </ul>
        </li> -->
      </ul>
    </div>
    <!--div class="tags">
      <ul class="tag-list">
         <li v-for="(tag, index) in tagList" @click="selectTag(tag.key)" v-bind:key="index">
          <div :class="{'selectedTag' : tag.selected}">#{{tag.key.toUpperCase()}}</div>
        </li>
      </ul>
    </div-->
  </div>
</div>
</template>

<script>

export default {
  name: 'BlogPage',
  components: {
  },
  data() {
    return {
      selectedBlog: {},
      keyWord: '',
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>
.container{
  padding-top: 40px;
  color: #606060;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.contents{
  width: 640px;
  padding: 10px;
}

.tool{
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
  width: 200px;
  height: 800px;
  padding: 10px;
  padding-top: 20px;
  margin-left: 20px;
}


.search{
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  justify-content: space-between;
  border-bottom:  1px solid #999;
}

.search input{
  border-style: none;
  padding: 5px 10px;
  border-radius: 4px;
  width: 160px;
  background-color: #fff;
  border:  1px solid #999;
}

.search input:focus{
  outline: 0;
}

.search img{
  width: 24px;
  height: auto;
  filter: brightness(1.5);
  margin-left: 5px;
}

.search img:hover{
  /*transform: scale(1.1);*/
  filter: brightness(2.0);
  cursor: pointer;
}

.tag-list{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #999;
}

.tag-list li:hover{
  cursor: pointer;
}

.selectedTag{
  color: #E6C02D;
}

</style>
