<template>
<div class="container-project">
  <div class="content">
    <div v-html="markdownToHtml" class="markdown-content"></div>
  </div>
</div>
</template>

<script>
import marked from 'marked';
import * as fb from '../firebase';
import getProjectsMixin from './get-project-mixin';

export default {
  name: 'Project',
  mixins: [getProjectsMixin],
  data() {
    return {
      projectId: '',
      template: '',
      markdown: " # Project Art ",
    };
  },
  created() {
    console.log('created ProjectPage');
    console.log(this.$route.params.id);
    console.log(this.$route.params.template);
    this.projectId = this.$route.params.id;
    this.template = this.$route.params.template;
    this.getMarkdown();
  },
  computed: {
    // selectedProject() {
    //   const proj = this.projects.filter(proj => proj.id === this.projectId);
    //   return proj[0];
    // },
    markdownToHtml() {
      //const proj = this.projects.filter(proj => proj.id === this.projectId);
      //const templateName = proj[0].template;
      return marked(this.markdown);
    },
  },
  methods: {
    async getMarkdown() {
      console.log('get markdown file');
      const storageRef = fb.storage.ref();
      const markdownsRef = storageRef.child('markdowns');
      console.log(markdownsRef);
      const templateRef = markdownsRef.child(this.template);
      console.log(templateRef);
      await templateRef.getDownloadURL().then((url) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {
          var blob = xhr.response;
          console.log(blob);
        };
        xhr.open('GET', url);
        xhr.send();
      }).catch((error) => {
        console.log('error: ', error);
      });
    },
  },
};

</script>

<style scoped>
.container-project {
  width: 100%;
  padding-top: 40px;
}

.content {
  width: 640px;
  padding: 10px;
  margin: 0 auto;
}
</style>