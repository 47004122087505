<template>
  <div class="app">
    <nav class="nav-bar">
      <div class="logo">
        <router-link :to="{path: '/home'}" class="menu-link">
          <img src="./assets/icon/tiny_logo.png">
        </router-link>
      </div>
      <ul class="nav-row">
        <li v-for="(menu, index) in menus" :key="index">
          <router-link :to="{path: '/'+menu.id}" class="menu-link">{{menu.name}}
          </router-link>
        </li>
      </ul>
    </nav>
    <!-- <HomePage/> -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  head: {
    link: [
      {
        rel: 'icon',
        href: require('./assets/icon/favicon.png')
      },
    ]
  },
  metaInfo: {
    meta: {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    },
  },
  created() {
    document.title = 'LABIREM - International laboratory of metadisciplinary research and expertise'
  },
  data() {
    return {
      menus: [
        {
          id: 'gallery',
          name: 'Gallery',
        },
        {
          id: 'blog',
          name: 'Blog',
        },
        {
          id: 'team',
          name: 'Team',
        },
        {
          id: 'about',
          name: 'About Us',
        },
      ],
    };
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mansalva&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Bitter&display=swap');
body{
  background-color: #fff;
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  html{
    overflow-y: scroll;
  }
}
</style>

<style scoped>
#app{
  position: absolute;
  width: 100%;
  height: 100%;
}

/* mobile phone portrait or landscape*/
@media only screen and (max-device-width: 767px) {
  .nav-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 0px 40px;
  }
  .nav-row li{
    list-style: none;
    padding: 0 6px;
  }
  .logo img{
    width: 26px;
    padding-right: 6px;

  }
}

/* Medium devices (landscape tablets, 768px and up) */
/* And Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 768px) {
  .nav-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10px 40px;
  }
  .nav-row li{
    list-style: none;
    padding: 0 20px;
  }
  .logo img{
    width: 26px;
    padding-right: 10px;
    filter: invert(0.4);
  }
}

.nav-row{
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'Inter', sans-serif;
}

.menu-link{
  text-decoration: none;
  color: #111;
}

@media (hover:hover) {
  .menu-link:hover{
    text-decoration: none;
    color: #999;
  }
  .logo img:hover{
    filter: invert(0.8);
  }
}

.router-link-active{
  color: #999;
}

</style>
