import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../home/HomePage.vue';
import TeamPage from '../team/TeamPage.vue';
import BlogPage from '../blog/BlogPage.vue';
import AboutPage from '../about/AboutPage.vue';

import BlogPreview from '../blog/BlogPreview.vue';
import BlogDetail from '../blog/BlogDetail.vue';

import GalleryPage from '../gallery/GalleryPage.vue';
import ProjectPage from '../gallery/ProjectPage.vue';

Vue.use(Router);

export default new Router({
  routes: [{
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: GalleryPage,
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: ProjectPage,
  },
  {
    path: '/team',
    name: 'Team',
    component: TeamPage,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogPage,
    children: [
      {
        path: '',
        component: BlogPreview,
      },
      {
        path: ':id',
        component: BlogDetail,
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
  }],
});
