// import VueMeta from 'vue-meta';
import Vue from 'vue';
import VueHead from 'vue-head';
import VueGoogleApi from 'vue-google-api';
import App from './App.vue';

import router from './router';
import store from './store';

Vue.config.productionTip = false;

const config = {
  apiKey: 'AIzaSyD-AIzaSyAcEb7hNIZmV94ogkKtA9ar7SzXw6Vn1gk',
  clientId: '727265480784-58ae6e6h8c7sjes8d71k4330s8hgs47f.apps.googleusercontent.com',
  scope: 'https://www.googleapis.com/auth/blogger',
  discoveryDocs: ['https://content.googleapis.com/discovery/v1/apis/blogger/v3/rest']
};

Vue.use(VueGoogleApi, config);
Vue.use(VueHead);

// Vue.use(VueMeta);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
