import { render, staticRenderFns } from "./TeamPage.vue?vue&type=template&id=08e6c614&scoped=true&"
import script from "./TeamPage.vue?vue&type=script&lang=js&"
export * from "./TeamPage.vue?vue&type=script&lang=js&"
import style0 from "./TeamPage.vue?vue&type=style&index=0&id=08e6c614&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e6c614",
  null
  
)

export default component.exports