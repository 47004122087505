<template>
<div class="container-preview">
  <ul class="list">
    <li v-for="(post, index) in posts" :key="index" class="item">
      <router-link :to="{path: '/blog/'+post.id}" :selectedPostId="post.id" class="link">
        <div class="name" @click="selectPost(post.id)">{{post.title}}</div>
      </router-link>
      <div class="gray">{{post.published}}</div>
      <div class="text">{{post.abstract}}</div>
    </li>
  </ul>
</div>
</template>

<script>

export default {
  name: 'BlogPreview',
  data() {
    return {
      posts: [],
      client: {},
      selectedPostId: 0,
    };
  },
  computed: {
  },
  created() {
    console.log('create Blog Preview page');
  },
  methods: {
    selectPost(postId) {
      console.log(postId);
      this.selectedPostId = postId;
    },
    loadClientLib() {
      return this.$gapi._libraryLoad('client')
        .then(client => {
          console.log(client);
          this.client = client;
          console.log(this.client);       
      });
    },
    async loadBloggerAPI() {
      const tmpClient = this.client;
      return this.client.load('https://content.googleapis.com/discovery/v1/apis/blogger/v3/rest')
        .then(function() {
          console.log(tmpClient);
          console.log('GAPI client loaded for API');
        },
        function(err){ console.error("Error loading GAPI client for API", err); });
    },
    loadPostList() {
      console.log(this.client);
      const tmpPosts = this.posts;
      return this.client.blogger.posts.list({
        "blogId": "1110973876315413902", // BLOG LABIREM
        "key": 'AIzaSyBUlzZA15NTB2muroGa00iRVFNNeScLU-U' // API key to use Blogger API
      })
      .then(function(response) {
        console.log(tmpPosts);
        // Handle the results here (response.result has the parsed body).
        console.log("Response", response);
        var items = response.result.items;
        console.log(items);
        tmpPosts.length = 0;
        items.forEach((element) => {
          const b = {
            "id": element.id,
            "title": element.title,
            "published": element.published,
          };
          tmpPosts.push(b);
          console.log(tmpPosts);
        });
        },
        function(err) { console.error("Execute error", err); });
    }
  },
  mounted() {
    this.loadClientLib().then(() => {
      console.log(this.client);
      this.loadBloggerAPI().then(() => {
        console.log(this.client);
        this.loadPostList().then(() => {
          console.log(this.posts);
        });
      });
    });
  },
};
</script>

<style scoped>
.container-preview{
  width: 100%;
  display: flex;
  flex-direction: columns;
}

.list{
  list-style: none;
  margin: 0;
  padding: 0;
}

.item{
  padding: 0;
  border-bottom: 1px solid #999;
  margin-bottom: 30px;
}

.name{
  font-size: 24px;
  font-family: 'Inter', serif;
  font-weight: 400;
  color: #1989B5;
}

.name:hover{
  text-decoration: underline;
  cursor: pointer;
}

.gray{
  color: #999;
}

.text{
  text-align: justify;
}

.link{
  text-decoration: none;
  color: inherit;
}
</style>
