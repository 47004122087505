import * as fb from '../firebase';

export default {
  created() {
  },
  computed: {
    images() {
      console.log(this.$store.state.images.images);
      return this.$store.state.images.images;
    },
  },
  methods: {
    async getImageById(filename) { // not used
      console.log('mixin: getImageById: ');
      console.log(filename);
      const storageRef = fb.storage.ref();
      const imagesRef = storageRef.child('images/team');
      const themeImgRef = imagesRef.child(filename);
      await themeImgRef.getDownloadURL().then((url) => {
        console.log(url);
        return url;
      }).catch((error) => {
        console.log('error: ', error);
      });  
    },
  },
};
