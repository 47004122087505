<template>
<div class="gallery">
  <ul class="container">
    <li v-for="(project, index) in projects" v-bind:key="index" class="row">
      <div class="project-overview" @click="goToProject(project.id, project.template)" v-bind:class="{'row-reverse': index % 2 === 1}">
        <div class="image">
          <ImageDisplay :filename="`projects/${project.profile}`" v-if="project.profile" class="profile"></ImageDisplay>
          <img src="../assets/images/full_logo_fr_Fr.png" class="default-image" v-if="!project.profile">
        </div>
        <div class="intro">
          <div class="name">{{project.name}}</div>
          <div class="date">{{getDate(project.date)}}</div>
          <div class="description" v-if="project.description">{{project.description}}</div>
        </div>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
import ImageDisplay from '../components/ImageDisplay.vue';
import getProjectsMixin from './get-project-mixin';

export default {
  name: 'Gallery',
  mixins: [getProjectsMixin],
  components: {
    ImageDisplay,
  },
  data() {
    return {
      mockProjList: [
        {
          'name': 'Project Art',
          'id': 'proj-1',
          'date': '01-05-2023',
          'discription': 'qsdfqfqfiodyi hiqsdhfsdfiochf qfhcdf'
        },
        {
          'name': 'Project Mochi',
          'id': 'proj-2',
          'date': '02-03-2022',
        },
        {
          'name': 'Bon anniversaire Mochi',
          'id': 'proj-3',
          'date': '02-03-2022',
        },
        {
          'name': 'Titi',
          'id': 'proj-4',
          'date': '02-03-2022',
        },
        {
          'name': 'Toto',
          'id': 'proj-5',
          'date': '02-03-2022',
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('projects/loadProjects');
  },
  methods: {
    goToProject(id, template) {
      console.log('go to '+ id);
      console.log('use template '+ template);
      this.$router.push({
        name: 'Project',
        params: {
          id,
          template,
        },
      });
    },
    getDate(timestamp) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toDateString();
    }
  },
};
</script>

<style scoped>
.gallery {
  width: 100%;
  padding-top: 40px;
}

.container {
  width: 100%;
  display: flex;
  list-style-type: none;
  flex-direction: column;
  padding: 0px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.project-overview {
  align-items: center;
  justify-content: center;
  color: #606060;
  padding: 60px 0px;
  display: flex;
  flex-direction: row;
}

.project-overview:hover{
  background: rgba(223, 128, 104, 0.233);
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.image {
  width: 400px;
  height: 400px;
  padding: 0px;
  margin: 0px 20px;
  /* background-color: rgba(223, 128, 104, 0.233); */
  border-radius: 50%; /* round */
}

.profile {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.default-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: rgba(223, 128, 104, 0.233);
  border-radius: 50%; /* round */
}

.intro {
  width: 400px;
  margin: 20px;
  text-align: left;
  text-decoration: none;
}

.name {
  font-size: 32px;
  padding: 20px;
}

.date {
  color: #999;
  padding: 20px;
}

.description {
  padding: 20px;
  text-align: left;
}

</style>