import { render, staticRenderFns } from "./ImageDisplay.vue?vue&type=template&id=2002612a&scoped=true&"
import script from "./ImageDisplay.vue?vue&type=script&lang=js&"
export * from "./ImageDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2002612a",
  null
  
)

export default component.exports