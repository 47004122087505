export default {
  namespaced: true,
  state: {
    blogs: [],
    displayedBlogs: [],
  },
  mutations: {
    updateBlogs(state, blogs) {
      state.blogs = blogs;
    },
    initTags(state, tags) {
      state.tags = tags;
    },
    updateTag(state, key) {
      const index = state.tags.findIndex((obj) => obj.key === key);
      state.tags[index].selected = !state.tags[index].selected;
    },
    sortByTag(state) {
      state.displayedBlogs = state.blogs.filter((blog) => {
        const list = blog.tags.filter((tag) => {
          const index = state.tags.findIndex((obj) => obj.key === tag);
          return state.tags[index].selected;
        });
        return list.length;
      });
    },
    searchByKeyWord(state, keyWord) {
      // TODO
      state.displayedBlogs = state.blogs.filter(
        (blog) => blog.name.toLowerCase().includes(keyWord),
      );
    },
  },
  actions: {
    loadBlogs({ commit }) {
      console.log('load blogs list');
      commit('updateBlogs', {"toto": "titi"});
    },
    searchByKeyWord({ commit }, keyWord) {
      commit('searchByKeyWord', keyWord);
    },
  },
  getters: {
    getBlogById: (state) => (blogId) => {
      console.log(blogId);
      return state.blogs.filter((blog) => blog.id === blogId)[0];
    },
    getSelectedTags(state) {
      return state.tags.filter((tag) => tag.selected);
    },
  },
};
