import * as fb from '../../firebase';

export default {
  namespaced: true,
  state: {
    images: [],
  },
  mutations: {
    updateImages(state, images) {
      state.images = images;
    },
  },
  actions: {
    loadImagesByTheme({ commit }, themeId) {
      console.log('load image by theme');
      console.log(themeId);
      const imgArr = [];
      const imagesRef = fb.storage.child('images/mock');
      const themeImgRef = imagesRef.child(themeId);
      themeImgRef.listAll().then((res) => {
        res.items.forEach((itemRef) => {
          console.log(itemRef);
          imgArr.push(itemRef);
        });
      }).catch((error) => {
        console.log('error: ', error);
      });
      commit('updateImages', imgArr);
    },
    loadFilenamesByTheme({ commit }, themeId) {
      const imgArr = [];
      console.log('load filename by theme');
      console.log(themeId);
      fb.db.collection('themes-mock').doc(themeId.themeId).collection('images').get()
        .then((items) => {
          items.forEach((item) => {
            console.log(`${item.id}`);
            const docRef = fb.db.collection('themes-mock').doc(themeId.themeId).collection('images').doc(item.id);
            docRef.get()
              .then((img) => {
                if (img.exists) {
                  const imgData = img.data();
                  imgData.id = item.id;
                  imgArr.push(imgData);
                }
              });
          });
        });
      console.log(imgArr);
      commit('updateImages', imgArr);
    },
    loadTeamFilenames({ commit }) {
      const imgArr = [];
      console.log('load team filenames');
      fb.db.collection('core_team').doc('en-US').collection('members').orderBy('order', 'asc').get()
        .then((items) => {
          items.forEach((item) => {
            console.log(`${item.id}`);
            const docRef = fb.db.collection('core_team').doc('en-US').collection('members').doc(item.id);
            docRef.get()
              .then((it) => {
                if (it.exists) {
                  const itData = it.data();
                  console.log(itData);
                  itData.id = item.id;
                  imgArr.push(itData);
                }
              });
          });
        });
      console.log(imgArr);
      commit('updateImages', imgArr);
    },
  },
  getters: {
    getImagesByTheme:
    (state) => (themeId) => ((state.images.filter((image) => image.theme === themeId))),
    getImageById: (state) => (id) => ((state.images.filter((image) => {
      console.log(image);
      console.log(id);
      return image.id === id;
    }))),
  },
};
