<template>
  <img :src="link">
</template>

<script>
import * as fb from '../firebase';

export default {
  name: 'ImageDisplay',
  props: [
    'filename',
  ],
  data() {
    return {
      link: 'link',
    };
  },
  created() {
    console.log('ImageComponent is created');
    console.log(this.filename);
    this.getLink();
  },
  methods: {
    async getLink() {
      console.log('getLink from ImageComponent');
      const storageRef = fb.storage.ref();
      const imagesRef = storageRef.child('images');
      const themeImgRef = imagesRef.child(this.filename);
      await themeImgRef.getDownloadURL().then((url) => {
        this.link = url;
      }).catch((error) => {
        console.log('error: ', error);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
