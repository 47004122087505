<template>
<div class="team">
  <div class="intro">
    <div class="team-name">{{team.name}}</div>
    <div class="team-description">{{team.description}}</div>
  </div>
  <ul class="member-container">
    <li v-for="(image, index) in images" v-bind:key="index" class="row">
      <div class="image">
        <ImageDisplay :filename="`team/${image.photo}`"></ImageDisplay>
      </div>
      <div class="description">
        <div class="name">{{image.first_name + ' ' + image.last_name}}</div>
        <div class="text">{{image.description}}</div>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
import ImageDisplay from '../components/ImageDisplay.vue';
import getTeamMixin from './get-team-mixin';
import getImagesMixin from './get-images-mixin';

export default {
  name: 'Team',
  mixins: [getTeamMixin, getImagesMixin],
  components: {
    ImageDisplay,
  },
  data() {
    return {
    };
  },
  created() {
    console.log('created TeamPage');
    this.$store.dispatch('team/loadTeam');
    this.$store.dispatch('images/loadTeamFilenames');
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>

.team{
  width: 100%;
  padding-top: 40px;
}
.intro{
  color: #606060;
  text-align: center;
}
.intro div{
  padding-top: 10px;
  padding-bottom: 50px;
  width: 50%;
  margin: 0 auto;
}
.team-name{
  font-size: 40px;
  padding: 50px 0px;
}
.team-description{
  font-size: 18px;
  text-align: justify;
}
.image-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  padding: 20px 0;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 20px;
}
.row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #606060;
  margin: 50px 0px;
}
.image{
  width: 320px;
  height: 320px;
  padding: 0;
}
.image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.description{
  width: 400px;
  padding-left: 20px;
  text-align: justify;
}
.description div{
  padding: 10px 0px;
}
.name {
  font-size: 32px;
}

</style>
