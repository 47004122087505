<template>
<div class="container-detail">
  <div v-if="!loading.state">
    <div class="title">{{selectedPost.title}}</div>
    <div class="date">{{selectedPost.published}}</div>
  </div>
  <div id="content"></div>
</div>
</template>

<script>

export default {
  name: 'BlogDetail',
  data() {
    return {
      postId: this.$route.params.id,
      selectedPost: {},
      loading: {"state": false},
    };
  },
  created() {
    console.log("create blog detail page");
    this.loadPost();
  },
  computed: {
  },
  methods: {
    async loadPost() {
      console.log('loadPost' + this.postId);
      this.loading.state = true;
      const tmpLoading = this.loading;
      const tmpPostId = this.postId;
      const tmpPost = this.selectedPost;
      this.$gapi._libraryLoad('client')
        .then(client => {
          return client.load('https://content.googleapis.com/discovery/v1/apis/blogger/v3/rest')
            .then(function() {
              console.log(tmpPostId);
              return client.blogger.posts.get({
                "blogId": "1110973876315413902", // BLOG LABIREM
                "key": 'AIzaSyBUlzZA15NTB2muroGa00iRVFNNeScLU-U', // API key to use Blogger API
                "postId": tmpPostId,
              })
              .then(function(response) {
                // Handle the results here (response.result has the parsed body).
                console.log("Response", response);
                tmpPost.title = response.result.title;
                tmpPost.published = response.result.published;
                tmpLoading.state = false;
                var content = document.getElementById("content");
                console.log(content);
                content.innerHTML = response.result.content;
              },
              function(err) { console.error("Execute error", err); });
            },
            function(err){ console.error("Error loading GAPI client for API", err); });
              
      });
    }
  },
};
</script>

<style scoped>
.container-detail{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.title{
  font-size: 32px;
  font-family: 'Inter', serif;
  font-weight: 600;
  padding: 10px 0;
  color: #1989B5;
  text-align: center;
}

.gray{
  color: #999;
  padding: 0;
}

#content {
  text-align: justify;
  list-style: none;
  padding-top: 10px;
  margin: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}

#content img{
  max-width: 100%;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}
</style>
