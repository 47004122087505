import Vue from 'vue';
import Vuex from 'vuex';

import teamModule from './modules/team';
import imagesModule from './modules/images';
import blogsModule from './modules/blogs';
import projectsModule from './modules/projects';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    team: teamModule,
    images: imagesModule,
    blogs: blogsModule,
    projects: projectsModule,
  },
});
