<template>
  <div class="home">
    <div class="logo-container">
      <img src="../assets/images/full_logo_fr_Fr.png" class="image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
};

</script>

<style scoped>
/* mobile phone portrait or landscape*/
@media only screen and (max-device-width: 768px) {
}

/* Medium devices (landscape tablets, 768px and up) */
/* And Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 768px) {
}

.home{
  width: 100%;
  padding-top: 40px;
}

.logo-container{
  width: 100%;
  display: flex;
  justify-content: center;
}

.image{
  max-width: 640px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

</style>
