import * as fb from '../../firebase';

export default {
  namespaced: true,
  state: {
    team: {},
  },
  mutations: {
    updateTeam(state, team) {
      state.team = team;
    },
  },
  actions: {
    loadTeam({ commit }) {
      console.log("load team");
      fb.db.collection('core_team').doc('en-US').get()
        .then((t) => {
          if (t.exists) {
            const tData = t.data();
            console.log(tData);
            commit('updateTeam', tData);
          } else {
            console.log('No such document');
          }
        }).catch((error) => {
          console.log('error: ', error);
        });
    },
  },
  getters: {
  },
};