import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

// firebase init
const firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: 'labirem-app',
  storageBucket: 'gs://labirem-app.appspot.com',
  messagingSenderId: '',
  appId: '',
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const storage = firebase.storage();
// const auth = firebase.auth();

// collection references
// const themesCollection = db.collection('themes-mock');
// const blogsCollection = db.collection('blogs');

// export utils and refs
export {
  db,
  storage,
};
