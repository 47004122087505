<template>
<div class="container">
  <div class="profile">
    <div class="image">
      <img src="../assets/icon/profile2.png">
    </div>
    <div class="info">
      <div class="name">{{artist.name}}</div>
      <div class="career">{{artist.career}}</div>
      <div>{{artist.description}}</div>
    </div>
  </div>
  <div class="row">
    <div class="grid"></div>
    <div class="grid"></div>
    <div class="grid"></div>
    <div class="grid extra"></div>
  </div>
  <div class="about">
    <div class="title">Something that I want to say about the site</div>
    <div class="site">{{artist.site}}</div>
    <div class="row">
      <div class="grid"></div>
      <div class="grid"></div>
      <div class="grid"></div>
      <div class="grid extra"></div>
    </div>
  </div>
  <ContactPage></ContactPage>
</div>
</template>

<script>
import ContactPage from './ContactPage.vue';

export default {
  name: 'About',
  components: { ContactPage },
  data() {
    return {
      artist: {
        name: 'Dr. Who',
        career: 'Physician, musician, artist and Time Lord',
        description: 'Egestas sapien lorem at diam. Quisque congue leo neque, at varius odio suscipit sit amet. Vivamus placerat aliquet eros vitae facilisis. Aliquam at orci eget velit lacinia convallis et volutpat magna. Nulla sit amet odio vulputate libero congue dictum. Morbi sodales, elit varius mattis fermentum, massa risus fermentum ex, non dignissim nibh elit et mauris. Phasellus eu venenatis sem. Cras vitae volutpat quam. Aliquam vel tortor vitae neque scelerisque volutpat. Morbi auctor, ipsum ut ullamcorper sodales, lectus ligula vehicula ligula, ac auctor libero ipsum non ante.',
        site: 'Vivamus dapibus ut orci ac scelerisque. Nam non dignissim augue, sit amet commodo risus. Fusce pulvinar sollicitudin felis vitae volutpat. Maecenas fringilla ultricies nisl, eget viverra dui bibendum placerat. Vestibulum vitae dolor ut tellus luctus sollicitudin.  Ut quis orci placerat, egestas tellus a, pulvinar orci. Morbi placerat dignissim quam, et tempor urna suscipit non.',
      },
    };
  },
};
</script>

<style scoped>
/* mobile phone portrait or landscape*/
@media only screen and (max-device-width: 768px) {
  .profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    text-align: justify;
    width: 100%;
  }
  .container{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    color: #606060;
  }
  .info div{
    padding: 10px 0;
  }
  .extra{
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
/* And Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 768px) {
  .profile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: justify;
    width: 800px;
  }
  .container{
    width: 800px;
    margin: auto;
    color: #606060;
  }
  .info{
    flex: 50%;
  }

  .info div{
    padding: 10px;
  }

}

.about{
  padding-top: 20px;
  color: #606060;
}

.row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0;
}

.grid{
  width: 24px;
  height: 24px;
  background-color: #CCC;
  border-radius: 50%;
  color: #999;
}

.character{
  font-size: 18px;
  text-align: center;
}

.image{
  flex: 50%;
  align-items: center;
}

.profile img{
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

.name{
  font-size: 24px;
  font-weight: bold;
}

.career{
  font-style: italic;
}

.site{
  text-align: justify;
  padding: 20px 0;
}

.art{
  text-align: justify;
  padding: 20px 0;
}

.title{
  font-style: 20px;
  text-align: center;
}
</style>
