import * as fb from '../../firebase';

export default {
  namespaced: true,
  state: {
    projects: [],
  },
  mutations: {
    updateProjects(state, projects) {
      state.projects = projects;
    },
  },
  actions: {
    loadProjects({ commit }) {
      const projArr = [];
      console.log("load projects");
      fb.db.collection('projects').orderBy('date', 'desc').get()
        .then((items) => {
          items.forEach((item) => {
            console.log(`${item.id}`);
            const docRef = fb.db.collection('projects').doc(item.id);
            docRef.get()
              .then((it) => {
                if (it.exists) {
                  const itData = it.data();
                  console.log(itData);
                  itData.id = item.id;
                  projArr.push(itData);
                }
              });
          });
          commit('updateProjects', projArr);
        }).catch((error) => {
          console.log('error: ', error);
        });
    },
  },
};