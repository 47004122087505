<template>
<div class="contact">
  <div class="content">
    <div class="art">
      <div class="intro">
      For more discussions and information about LabIREM, please contact us:</div>
      <div>{{contact.mail}}</div>
      <!--div>{{contact.tel}}</div-->
      <!--div>{{contact.address}}</div-->
      <div class="icon">
        <img src="../assets/icon/f_logo_RGB-White_512.png">
      </div>
      <!--div class="icon">
        <img src="../assets/icon/glyph-logo_instagram.png">
      </div-->
    </div>
    <div class="technique">
      <div class="intro">
      If you encoutered any technical problem on the site, please kindly inform us :)</div>
      <div>{{contact.tech}}</div>
      <div class="icon">
        <img src="../assets/icon/github_white_png_official.png">
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Contact',
  data() {
    return {
      contact: {
        mail: 'contact@labirem.com',
        tel: '+33 06 65 00 51 92',
        address: '11 Rue Non-Connue, Paris, 75000, France',
        facebook: 'link',
        instagram: 'link',
        github: 'https://github.com/LabIREM',
        tech: 'support@labirem.fr',
      },
    };
  },
};
</script>

<style scoped>
/* mobile phone portrait or landscape*/
@media only screen and (max-device-width: 768px) {
  .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 20px;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .grid{
    width: 32px;
    height: 32px;
    background-color: #CCC;
    border-radius: 50%;
    color: #999;
  }
  .character{
    font-size: 24px;
    text-align: center;
  }
  .extra{
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
/* And Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 768px) {
  .content{
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 20px;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .grid{
    width: 40px;
    height: 40px;
    background-color: #CCC;
    border-radius: 50%;
    color: #999;
  }
  .character{
    font-size: 28px;
    text-align: center;
  }
}

.contact{
  padding-top: 20px;
  color: #606060;
}

.content div{
  padding: 10px;
}

.art{
  padding-top: 40px;
}

.intro {
  font-style: italic;
  color: #606060;
}

.icon{
  width:40px;
  margin: auto;
}

.icon img{
  width:100%;
  filter: brightness(0.75);
}

.icon img:hover{
  transform: scale(1.1);
  cursor: pointer;
}

.logo-grid{
  margin-bottom: 30px;
}

.row{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

</style>
